import React from 'react';
import footerLogo from "../../assets/footer-logo.png";
import { BsInstagram, BsTwitter } from "react-icons/bs"
import {NavLink} from "react-router-dom";
import { FaFacebookF } from "react-icons/fa"
import "./Footer.css";

const Footer = () => {
    return (
        <React.Fragment>
            <div className="main_footer_div">
                <div className="container">
                    <div className="row footer_row">
                        <div className="col-md-3 footer_first_col">
                            <div className="footer_logo_img">
                                <img src={footerLogo} alt="footer-logo" />
                            </div>
                            <p>RT Cloud Solutions is a cloud services provider. We love helping startups and small to medium sized businesses realize cost, technical, and operational efficiencies in the cloud.</p>
                        </div>
                        <div className="col-md-2 footer_page_link">
                            <div className='info_div'>
                                <p>PAGE LINKS</p>
                            </div>
                            <ul>
                            <NavLink to={'/'}><li>Home</li></NavLink>
                            <NavLink to={'/about-us'}><li>About Us</li></NavLink>
                            <NavLink to={'/service'}><li>Service</li></NavLink>
                            <NavLink to={'/contact-us'}><li>Contact Us</li></NavLink>
                            </ul>
                        </div>
                        <div className="col-md-3 footer_page_link">
                            <div className='info_div'>
                                <p>CONTACT</p>
                            </div>
                            <div>
                                <ul>
                                    <li>903 505 1837</li>
                                    <li>info@rtcloudsolutions.cloud</li>
                                    <li>Addison, TX</li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-md-4 newsletter_div">
                            <div className='info_div'>
                                <p>NEWSLETTER</p>
                            </div>
                            <div>
                                <p>Subscribe For Updates</p>
                            </div>
                            <div>
                                <input className="footer_input" placeholder="Your Email Here" />
                                    <button className={'submit_btn'}>Submit</button>
                            </div>

                            <div className='footer_social_icons'>
                                <div className='icon_div'>
                                    <FaFacebookF />
                                </div>
                                <div className='icon_div'>
                                    <BsInstagram />
                                </div>
                                <div className='icon_div'>
                                    <BsTwitter />
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

                <div className='copy_rights_div'>
                    <div className='container'>
                    <hr style={{backgroundColor: "#fff"}}/>
                    </div>
                    <p className='text-center'>Copyright 2022 RT Cloud Solutions. All Rights Reserved</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Footer;
